import axios from "axios";

import Vue from "vue";
import qs from "qs";
// const BASE_URL = 'http://www.apitp6.com'
// const BASE_URL = "https://uat-merapi.aidogcatpet.com";
const BASE_URL = "https://merapi.aidogcatpet.com";

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: 50000,
});

const instance2 = axios.create({
  timeout: 50000,
  
});

// 添加请求拦截器
instance.interceptors.request.use(
  function (config) {
    // eslint-disable-next-line eqeqeq
    if (config.method == "post") {
      config.data = qs.stringify(config.data);
    }
    var token = window.localStorage.getItem("token") || "";

    if (token && config.url != "/merapi/login/login") {
      config.headers.Authorization = token;
    }
    // 在发送请求之前做些什么
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  function (res) {
    return res;
    // 对响应数据做点什么
  },
  function (err) {
    return Promise.reject(err);
  }
);

Vue.prototype.$http = instance;
Vue.prototype.$http2 = instance2;
